import jQuery from 'jquery';
window.$=jQuery;
// 
// import moment from 'moment';
// window.moment=moment;

import Vue from 'vue';
window.Vue=Vue;

import SysStatusPub from './SysStatusPub.vue';
Vue.component('SysStatusPub', SysStatusPub);

$(function($) {
  window.vueMixins=window.vueMixins || {}
  $("[vue-dyn]").each(function() {
    var $this=$(this);
    var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
      return a;
    }).map(function(a) {
      return window.vueMixins[a];
    });
    $this.removeAttr("vue-dyn");
    (new Vue({
      el: this,
      mixins: mixins
    }));
  });
});
